function initializeMap(containerId, accessToken, center = [10.4515, 51.1657], zoom = 3) {
    mapboxgl.accessToken = accessToken;
    return new mapboxgl.Map({
        container: containerId,
        style: 'mapbox://styles/mapbox/light-v11',
        center: center,
        zoom: zoom,
        padding: { right: 0, left: window.innerWidth * 0.33 },
    });
}

window.initializeMap = initializeMap;